import React from 'react';
import { Box, Chip } from '@mui/material';
import { Tag } from '../types';
import styles from '../style.module.css';

interface TagListProps {
  tags: Tag[];
  selectedTag: Tag | null;
  setSelectedTag: (tag: Tag | null) => void;
  maxVisibleTags?: number; // 横スクロールを開始するまでの最大タグ数
}

const TagList: React.FC<TagListProps> = ({ tags, selectedTag, setSelectedTag, maxVisibleTags = 1 }) => {
  const handleTagClick = (tag: Tag) => {
    if (selectedTag?.id !== tag.id) {
      setSelectedTag(tag);
    }
  };

  return (
    <div>
      <Box
        className={styles.sectionLine}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          maxWidth: '100%',
          padding: '8px',
        }}
      >
        {tags.map((tag) => (
          <Chip
            key={tag.id}
            label={tag.name}
            onClick={() => handleTagClick(tag)}
            // color={selectedTag?.id === tag.id ? 'primary' : 'default'}
            // variant={selectedTag?.id === tag.id ? 'filled' : 'outlined'} @TODO: 後で実装
            style={{ margin: '4px' }}
            sx={{ backgroundColor: selectedTag?.id === tag.id ? 'primary.main' : 'transparent' }}
          />
        ))}
      </Box>
    </div>
  );
};

export default TagList;

// ContentGrid.tsx
import React, { useEffect, useState } from 'react';
import { Grid, Pagination, Box } from '@mui/material';
import ArticleThumbnail from './ArticleThumbnail';
import { Tag } from '../types';
import ApiClient from '../utils/apiClient';
import { ARTICLE_CONTENTS_PER_PAGE } from '../utils/constants';

interface TagListProps {
  selectedTag: Tag | null;
}

type Content = {
  id: string;
  title: string;
  content: string;
  eyecatch: Record<string, any>;
  createdAt: string;
  tag: Tag[];
};

const ContentGrid: React.FC<TagListProps> = ({ selectedTag }) => {
  const [contents, setContents] = useState<Content[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  async function fetchArticles(selectedTag: Tag | null): Promise<void> {
    try {
      setIsLoading(true);
      let query: Record<string, string> | null;
      const offsetValue = ((currentPage - 1) * ARTICLE_CONTENTS_PER_PAGE);
      if (selectedTag === null || selectedTag.name === 'all') {
        query = { 'offset': offsetValue.toString(), 'limit': ARTICLE_CONTENTS_PER_PAGE.toString() };
      } else {
        query = { 
          'offset': offsetValue.toString(),
          'limit': ARTICLE_CONTENTS_PER_PAGE.toString(),
          'filters': `tag[contains]${selectedTag?.id}`
        };
      }
      const res = await ApiClient.get('blogs', query);
      setContents(res.contents);
      setTotalPages(Math.ceil(res.totalCount / ARTICLE_CONTENTS_PER_PAGE)); // 総ページ数を計算
    } catch (err) {
      console.error('記事取得失敗:', err);
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * タグが変更されるたびにfetchArticlesを実行
   */
  useEffect(() => {
    setCurrentPage(1);
    fetchArticles(selectedTag);
  }, [selectedTag]);

  /**
   * ページネーションが変更されるたびにfetchArticlesを実行
   */
    useEffect(() => {
      fetchArticles(selectedTag);
    }, [currentPage]);

  // ページ変更ハンドラー
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value); // ページ番号を更新
  };

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : contents && contents.length > 0 ? (
        <>
          <Grid container spacing={4}>
            {contents.map((content: Content, index: number) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <ArticleThumbnail content={content} />
              </Grid>
            ))}
          </Grid>
          {/* ページネーション */}
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </>
      ) : (
        <p>No article available.</p>
      )}
    </>
  );
};

export default ContentGrid;

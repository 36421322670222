import React from 'react';
import { AppBar, Toolbar, Typography, Box, Link } from '@mui/material';
import CottageIcon from '@mui/icons-material/Cottage';
import styles from '../style.module.css';

const Header: React.FC = () => (
  <AppBar
    position="static"
    sx={{ backgroundColor: 'transparent', boxShadow: 'none'}}
  >
    <Toolbar className={styles.muiToolBar}>
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Link
          href="/"
          sx={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          {/* プロフィールアイコン */}
          {/*
            <Avatar
              alt="User Icon"
              src="/icons/tomosibi.png"
              sx={{
                marginRight: 2,
                width: 56,
                height: 56,
                // backgroundColor: '#ffffff',
              }}
            />
          */}
          <Typography variant='h1' component="div">
            <CottageIcon sx={{ mt: 0.3 }} />
          </Typography>
          <Typography variant='h1' component="h1" sx={{ ml: 1 }} className={styles.headerTitle}>
            himitsukiti
          </Typography>
        </Link>
      </Box>
    </Toolbar>
  </AppBar>
);

export default Header;

import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => (
  <Box
    component="footer"
    sx={{
      padding: 2,
      textAlign: 'center',
      borderTop: '1px solid #ddd',
      marginTop: 4,
    }}
  >
    <Typography variant="body2" color="text.secondary">
      © 2024 himitsukiti. All Rights Reserved.
    </Typography>
  </Box>
);

export default Footer;
